import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import '../styles/CopyIt.sass'

const TIP_VISIBLE_DURATION = 3000
const TIP_ANIMATION_DURATION = 200

function CopyIt ({ text, children }) {
  const [tipClass, setTipClass] = useState('')

  function showTip () {
    // show tip and start animation
    const visibleClass = 'copyit__tip--visible'
    setTipClass(`${visibleClass} o-0`)
    setTimeout(() => {
      setTipClass(visibleClass)
    })
    setTimeout(() => {
      setTipClass(`${visibleClass} o-0`)
      setTimeout(() => {
        setTipClass('')
      }, TIP_ANIMATION_DURATION)
    }, TIP_VISIBLE_DURATION)
  }

  return (
    <div className="copyit">
      <div className={`copyit__tip ${tipClass}`}>複製成功</div>
      <CopyToClipboard text={text} onCopy={showTip}>
        {children ? (children) : (
          <button className="copyit__button button is-white">
            <i className="far fa-copy" />
          </button>
        )}
      </CopyToClipboard>
    </div>
  )
}

export default CopyIt
