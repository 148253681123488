import React, { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import CopyIt from './CopyIt'
import useSiteMetadata from './SiteMetadata'
import gateways from './gateways.json'
import '../styles/GatewayList.sass'

const MAX_N_GW = 6
const SHOW_GW_RATIO = 1/3

const GW_TEMPLAGE_TOKEN = '/ipfs/:hash'
const GW_TEST_TIMEOUT = 1000
const acceptableGateways = _.shuffle(
  gateways.filter(gw => gw.endsWith(GW_TEMPLAGE_TOKEN))
)

const GatewayList = (({ slug }) => {

  const { gatewayCid, gatewayAnswer, ipns } = useSiteMetadata()
  const now = Date.now()

  const [hasOpened, setHasOpened] = useState(false)
  const [availableGateways, setGateways] = useState([])
  const [gatewayCursor, setGatewayCursor] = useState(0)
  const [gatewayDone, setGatewayDone] = useState(0)
  const [isLoadingGateway, setIsLoadingGateway] = useState(true)
  const [isMenuActive, setMenuActive] = useState(false)

  const findAvailableGateway = useCallback(() => {
    // main reference: public-gateway-checker
    // https://github.com/ipfs/public-gateway-checker/blob/master/app.js
    async function checkGatewayAccess (gw) {
      gw = gw.replace(GW_TEMPLAGE_TOKEN, `/ipfs/${gatewayCid}`)
      const endpoint = `${gw}?now=${now}#x-ipfs-companion-no-redirect`
      try {
        // ping golden endpoint and wait for at most GW_TEST_TIMEOUT
        const resp = await new Promise((resolve, reject) => {
          const fetchTimer = setTimeout(() => {
            reject('gateway timeout')
          }, GW_TEST_TIMEOUT)
          fetch(endpoint).then(result => {
            clearTimeout(fetchTimer)
            resolve(result)
          })
        })
        let answer = await resp.text()
        answer = answer.trim()
        return answer === gatewayAnswer
      } catch (e) {
        // do nothing
      }
      return false
    }

    const nMissing = MAX_N_GW - availableGateways.length
    if (nMissing > 0) {
      const checkList = acceptableGateways.slice(gatewayCursor, gatewayCursor + nMissing)
      setGatewayCursor(old => old + nMissing)

      checkList.forEach(gw => {
        checkGatewayAccess(gw).then(isAvailable => {
          setGatewayDone(old => old + 1)
          if (isAvailable) {
            setGateways(oldList => [...oldList, gw])
          }
        })
      })
    }
  }, [availableGateways, gatewayCursor, gatewayAnswer, gatewayCid, now])

  function initDropdown () {
    if (!hasOpened) {
      setHasOpened(true)
      findAvailableGateway()
    }
  }

  function gatewayDomain (gw) {
    return gw.replace(GW_TEMPLAGE_TOKEN, '').replace('https://', '')
  }

  function gatewayUrl (gw) {
    return gw.replace(GW_TEMPLAGE_TOKEN, `/ipns/${ipns}${slug}`)
  }

  function toggleMenu (e) {
    e.stopPropagation()
    setMenuActive(!isMenuActive)
    if (!isMenuActive) {
      initDropdown()
    }
  }
  
  function hideMenu () {
    setMenuActive(false)
  }

  useEffect(() => {
    // action on update of movies
    if (!hasOpened) {
      return
    }
    if (gatewayDone >= gatewayCursor && availableGateways.length < MAX_N_GW && acceptableGateways.length) {
      findAvailableGateway()
    } else if (availableGateways.length > (MAX_N_GW * SHOW_GW_RATIO)) {
      setIsLoadingGateway(false)
    }
  }, [hasOpened, gatewayDone, gatewayCursor, availableGateways, findAvailableGateway])

  useEffect(() => {
    window.addEventListener('click', hideMenu)

    return () => {
      window.removeEventListener('click', hideMenu)
    }
  }, [])
  
  return (
    <div className={`gwlist dropdown ${isMenuActive ? 'is-active' : ''}`}>
      <div className="gwlist__trigger dropdown-trigger">
        <button
          className="button is-light"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onMouseEnter={initDropdown}
          onClick={toggleMenu}
        >
          <span className="icon is-small">
            <i className="fas fa-globe" aria-hidden="true"></i>
          </span>
          <span>分布式入口</span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-wrapper">
          <div className="dropdown-content">
            <div className="gwlist__title">
              关于分布式入口
              <button className="gwlist__close" onClick={hideMenu}>
                <i className="fas fa-times" />
              </button>
            </div>
            <div className="gwlist__desp">
              本页使用 IPFS 技术，页面内容分散储存于众多网站服务器上，你可以挑选以下任一网址在墙内无障碍阅读和传播本文：
            </div>
            <div className="gwlist__content">
              {isLoadingGateway ? (
                 <div className="gwlist__loading">
                   <i className="fas fa-circle-notch fa-spin" />
                 </div>
                ): availableGateways.map(gw => (
                  <div className="gwlist__item dropdown-item" key={gw}>
                    <a className="dim" href={gatewayUrl(gw)} rel="noopener noreferrer" target="_blank">{gatewayDomain(gw)}</a>
                    <CopyIt text={gatewayUrl(gw)}></CopyIt>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default GatewayList
