
import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import _ from 'lodash'
import ArticleThumbnail  from './ArticleThumbnail'
import SectionTitle from '../components/SectionTitle'
import '../styles/YouMayAlsoLike.sass'

// modify from https://khalilstemmler.com/articles/gatsby-related-posts-component/

export default ({ currentArticle, maxArticles = 4 }) => (
  <StaticQuery
    query={graphql`
      query YouMayAlsoLikeQuery {    
        posts: allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "article" } } }
          limit: 1000
        ) {
          edges {
            node {
              ...articleThumbFields
              frontmatter {
                tags
              }
            }
          }
        }
      }
    `}
    render={data => {
      const currentArticleId = currentArticle.id
      const { tags = [], columnist } = currentArticle.frontmatter

      const otherArticles = data.posts.edges.map(edge => edge.node).filter(
        (article) => {
          return article.id !== currentArticleId
        }
      )

      const pointForSameColumnist = 2
      const pointForSameTag = 1

      function getRelevanceScore (targetArticle) {
        const targetTags = targetArticle.frontmatter.tags || []
        const targetColumnist = targetArticle.frontmatter.columnist

        const tagScore = _.intersection(targetTags, tags).length * pointForSameTag
        let columnistScore = 0
        if (columnist && targetColumnist && columnist.meta.title === targetColumnist.meta.title) {
          columnistScore = pointForSameColumnist
        }

        return tagScore + columnistScore
      }

      let articleScores = otherArticles.map((article) => {
        return {
          article,
          relevance: getRelevanceScore(article)
        }
      }).filter(article => article.relevance > 0)
      articleScores = _.orderBy(
        articleScores, ['relevance'], ['desc']
      ).slice(0, maxArticles)

      if (articleScores.length === 0) {
        return
      }

      return (
        <div className="ymal">
          <SectionTitle> 相关文章 </SectionTitle>
          <div className="ymal__list">
            {articleScores.map(({ article }) => (
              <div
                className="ymal__card"
                style={{width: `${100/maxArticles}%`}}
                key={article.id}
              >
                <ArticleThumbnail
                  slug={article.fields.slug}
                  featuredimage={article.frontmatter.featuredimage}
                  title={article.frontmatter.title}
                  columnist={article.frontmatter.columnist}
                  excerpt={article.excerpt}
                  date={article.frontmatter.date}
                />
              </div>
            ))}
          </div>
        </div>
      )
    }}
  />
)