import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import GatewayList from '../components/GatewayList'
import CopyIt from '../components/CopyIt'
import YouMayAlsoLike from '../components/YouMayAlsoLike'
import '../styles/Article.sass'
import {
  FacebookShareButton,
  TwitterShareButton,
  WeiboShareButton,
  TelegramShareButton
} from 'react-share'

export const ArticleTemplate = ({
  content,
  contentComponent,
  tags,
  title,
  isbrief,
  date,
  contributors,
  slug,
  columnist,
  helmet,
}) => {
  let columnistTitle = columnist
  let columnistSlug = columnist
  if (columnist && columnist.fields) {
    columnistTitle = columnist.meta.title
    columnistSlug = columnist.fields.slug
  }
  const PostContent = contentComponent || Content
  const subHeaderStr = columnistTitle || (isbrief ? '早报' : '')
  let SubHeader = null

  if (columnistTitle) {
    SubHeader = (
      <Link className="dim" to={columnistSlug}>
        <h2 className="article__topic">{subHeaderStr}</h2>
      </Link>
    )
  } else if (isbrief) {
    SubHeader = (
      <Link className="dim" to="/daily-brief">
        <h2 className="article__topic">{subHeaderStr}</h2>
      </Link>
    )
  }

  let origin = 'https://'
  let pathname = ''
  // window is not defined during build, skip it
  // https://www.gatsbyjs.org/docs/debugging-html-builds/
  if (typeof window !== 'undefined') {
    // ignore query and hash to avoid possible injection
    origin = window.location.origin
    pathname = window.location.pathname
  }
  const articleUrl = `${origin}${pathname}`

  tags = (tags || []).filter(tag => !!tag)

  return (
    <section className="section">
      {helmet || ''}
      <article className="article content">
        <header className="article__header">
          {SubHeader}
          <h1 className="title is-2">{title}</h1>
        </header>
        <div className="article__main">
          <div className="article__sidebar">
            <div className="article__date">{date}</div>
            {contributors && contributors.length ? (
              <div className="article__contributor-list">
                {contributors.map((person, i) => (
                  <div className="contributor" key={i}>
                    <div className="contributor__type">{person.type}</div>
                    <div className="contributor__name">{person.name}</div>
                  </div>
                ))}
              </div>
            ):null}

            {tags && tags.length ? (
              <div className="article__tags tags">
                {tags.map(tag => (
                  <div className="tags__item dim" key={`${tag}tag`}>
                    <Link to={`/tag/${kebabCase(tag)}/`}>{tag}</Link>
                  </div>
                ))}
              </div>
            ):null}
            <div className="article__gateways">
              {slug ? (
                <GatewayList slug={slug} />
              ) : null}
            </div>
            <div className="article__shares">
              <FacebookShareButton className="dim" url={articleUrl}>
                <i className="fab fa-facebook-f"></i>
              </FacebookShareButton>
              <TwitterShareButton className="dim" url={articleUrl}>
                <i className="fab fa-twitter"></i>
              </TwitterShareButton>
              <WeiboShareButton className="dim" url={articleUrl}>
                <i className="fab fa-weibo"></i>
              </WeiboShareButton>
              <TelegramShareButton className="dim" url={articleUrl}>
                <i className="fab fa-telegram-plane"></i>
              </TelegramShareButton>
              <CopyIt text={articleUrl}>
                <button className="button is-white dim">
                  <i className="fas fa-copy" />
                </button>
              </CopyIt>
            </div>
          </div>
          <div className="article__content">
            <PostContent content={content} />
          </div>
        </div>
      </article>
    </section>
  )
}

ArticleTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const Article = ({ data }) => {
  const { markdownRemark: post, site } = data
  const title = `${post.frontmatter.title} | ${site.siteMetadata.title}`
  
  const coverMeta = []
  if (post.frontmatter.featuredimage) {
    // use main site img url as og:img require absolute path
    const img = post.frontmatter.featuredimage.childImageSharp.fixed
    const imgUrl = img.src.replace(/.*\/static\//, '/static/')
    const coverUrl = `${site.siteMetadata.mainSite}${imgUrl}`
    coverMeta.push(
      (<meta key="og:image" property="og:image" content={coverUrl} />),
      (<meta key="og:image:width" property="og:image:width" content={img.width} />),
      (<meta key="og:image:height" property="og:image:height" content={img.height} />),
      (<meta key="twitter:image" name="twitter:image" content={coverUrl} />)
    )
  }

  const description = post.frontmatter.description || post.excerpt

  return (
    <Layout>
      <ArticleTemplate
        content={post.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet>
            <title>{`${title}`}</title>
            <meta property="og:title" content={title} />
            <meta name="twitter:title" content={title} />
            <meta itemprop="name" content={title} />
            <meta property="og:image:alt" content={title} />

            <meta name="description" content={`${description}`} />
            <meta property="og:description" content={description} />
            <meta name="twitter:description" content={description} />
            <meta itemprop="description" content={description} />

            {coverMeta}

            <meta property="og:type" content="article" />
            <meta name="twitter:card" content="summary_large_image" />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        contributors={post.frontmatter.contributors}
        isbrief={post.frontmatter.isbrief}
        slug={post.fields.slug}
        columnist={post.frontmatter.columnist}
      />
      <section className="section">
        <div className="content">
          <YouMayAlsoLike currentArticle={post} />
        </div>
      </section>
    </Layout>
  )
}

Article.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    site: PropTypes.object
  }),
}

export default Article

export const pageQuery = graphql`
  query ArticleByID($id: String!) {
    site {
      siteMetadata {
        title,
        mainSite
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      excerpt(pruneLength: 60, truncate: true)
      frontmatter {
        date(formatString: "YYYY/MM/DD")
        title
        description
        columnist {
          ...simpleColumnistFields
        }
        featuredimage {
          childImageSharp {
            fixed(width: 1200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        tags
        contributors {
          type
          name
        }
        isbrief
      }
    }
  }
`
